/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"

const VideoPlayer = (props) => {
  return (
    <Section sx={{ variant: "section.xlarge" }}>
      <Container>
        <Columns>
          <Column
            sx={{
              variant: "styles",
              width: [null, null, null, "33.33333333%"],
              mb: -4,
            }}
          >
            {props.contentHeading && (
              <Themed.h2 id={props.contentHeadingId}>
                {props.contentHeading}
              </Themed.h2>
            )}
          </Column>
          <Column
            sx={{
              width: [null, null, null, "58.33333333%"],
              ml: [null, null, null, "8.33333333%"],
              variant: "styles",
            }}
          >
            <div
              sx={{
                position: "relative",
                paddingTop: "56.25%",
              }}
            >
              <ReactPlayer
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                url={props.videoUrl}
                width="100%"
                height="100%"
              />
            </div>
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

VideoPlayer.propTypes = {
  contentHeading: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
}

export default VideoPlayer
