/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"

const SmallImageBlock = (props) => {
  const gatsbyImageData = getImage(props?.image?.sourceLocal)
  return (
    <Section sx={{ variant: "section.xlarge" }}>
      <Container>
        <Columns>
          <Column
            sx={{
              variant: "styles",
              width: [null, null, null, "33.33333333%"],
              mb: -4,
            }}
          >
            {props.contentHeading && (
              <Themed.h2 id={props.contentHeadingId}>
                {props.contentHeading}
              </Themed.h2>
            )}
          </Column>
          <Column
            sx={{
              width: [null, null, null, "58.33333333%"],
              ml: [null, null, null, "8.33333333%"],
              variant: "styles",
            }}
          >
            <figure>
              {gatsbyImageData && (
                <GatsbyImage
                  image={gatsbyImageData}
                  alt={props.image.altText ?? ""}
                />
              )}
              {props.image && props.image.altText && (
                <figcaption sx={{ mt: 3 }}>{props.image.altText}</figcaption>
              )}
            </figure>
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default SmallImageBlock
