/** @jsx jsx */
import { jsx } from "theme-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"

const LargeImageBlock = (props) => {
  const gatsbyImageData = getImage(props?.image?.sourceLocal)
  return (
    <Section sx={{ variant: "section.xlarge" }}>
      <Container>
        <figure>
          {gatsbyImageData && (
            <GatsbyImage
              image={gatsbyImageData}
              alt={props.image.altText ?? ""}
            />
          )}
          {props.image && props.image.altText && (
            <figcaption sx={{ mt: 3 }}>{props.image.altText}</figcaption>
          )}
        </figure>
      </Container>
    </Section>
  )
}

LargeImageBlock.propTypes = {
  image: PropTypes.shape({
    sourceLocal: PropTypes.shape({
      childImageSharp: PropTypes.object.isRequired,
    }).isRequired,
  }),
}

export default LargeImageBlock
