/** @jsx jsx */
import { jsx, Themed, Flex } from "theme-ui"
import slugify from "slugify"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Link from "src/components/Link"

const TableOfContents = ({
  acfFeatureModules = { featureModulesContent: [] },
  acfBodyContent = { bodyContent: [] },
  acfRichContent = { richContent: [] },
}) => {
  const { featureModulesContent } = acfFeatureModules
  const { bodyContent } = acfBodyContent
  const { richContent } = acfRichContent

  const content = [...featureModulesContent, ...bodyContent, ...richContent]

  if (content.length === 0) return null

  return (
    <Section sx={{ variant: "section.medium" }}>
      <Container>
        <nav
          sx={{
            borderInlineStart: ".375rem solid",
            borderColor: "grey",
            borderImage:
              "linear-gradient(180deg, #B22304 0% 36%, #EDB936 36% 57%, #071C53 57% 70%, #62DEBD 70% 100%) 1 100%",
            paddingInlineStart: 6,
          }}
        >
          <Themed.h2
            sx={{
              marginBlockEnd: 4,
            }}
          >
            On this page
          </Themed.h2>
          <Flex
            as="ul"
            sx={{
              flexDirection: "column",
              gap: 3,
            }}
          >
            {content.length > 0 &&
              content.map((slice, index) => {
                const text =
                  typeof slice.contentHeading === "string"
                    ? slice.contentHeading
                    : typeof slice.sectionHeading === "string"
                    ? slice.sectionHeading
                    : typeof slice.statement === "string"
                    ? slice.statement
                    : typeof slice.title === "string"
                    ? slice.title
                    : ""

                return (
                  text && (
                    <li key={index}>
                      <Link href={`#${slugify(text)}`}>{text}</Link>
                    </li>
                  )
                )
              })}
          </Flex>
        </nav>
      </Container>
    </Section>
  )
}

export default TableOfContents
