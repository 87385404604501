/** @jsx jsx */
import { jsx } from "theme-ui"

import TextContent from "src/slices/TextContent"
import Accordion from "src/slices/Accordion"
import LargeImageBlock from "src/slices/LargeImageBlock"
import SmallImageBlock from "src/slices/SmallImageBlock"
import VideoPlayer from "src/slices/VideoPlayer"
import DetailCardsEditableTiles from "src/components/DetailCardsEditableTiles"
import slugify from "slugify"

function BodyContentRenderer({ acfBodyContent }) {
  const { bodyContent } = acfBodyContent
  if (bodyContent.length === 0) return null
  return (
    <div sx={{ variant: "spacing.small" }}>
      {bodyContent.map((slice, index) => {
        switch (slice && slice.__typename) {
          case "WPGraphQL_Page_Acfbodycontent_BodyContent_Wysiwyg":
          case "WPGraphQL_Article_Acfbodycontent_BodyContent_Wysiwyg":
          case "WPGraphQL_Resource_Acfbodycontent_BodyContent_Wysiwyg":
          case "WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_Wysiwyg":
          case "WPGraphQL_LegalIntervention_Acfbodycontent_BodyContent_Wysiwyg":
          case "WPGraphQL_PolicySubmission_Acfbodycontent_BodyContent_Wysiwyg":
            return (
              <TextContent
                key={index}
                contentHeading={slice.contentHeading}
                contentHeadingId={
                  slice.contentHeading
                    ? slugify(slice.contentHeading)
                    : undefined
                }
                content={slice.content}
              />
            )

          case "WPGraphQL_Page_Acfbodycontent_BodyContent_Accordion":
          case "WPGraphQL_Article_Acfbodycontent_BodyContent_Accordion":
          case "WPGraphQL_Resource_Acfbodycontent_BodyContent_Accordion":
          case "WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_Accordion":
          case "WPGraphQL_LegalIntervention_Acfbodycontent_BodyContent_Accordion":
          case "WPGraphQL_PolicySubmission_Acfbodycontent_BodyContent_Accordion":
            return (
              <Accordion
                key={index}
                contentHeading={slice.contentHeading}
                contentHeadingId={
                  slice.contentHeading
                    ? slugify(slice.contentHeading)
                    : undefined
                }
                display={slice.display}
                accordion={slice.accordion}
              />
            )

          case "WPGraphQL_Page_Acfbodycontent_BodyContent_LargeImageBlock":
          case "WPGraphQL_Article_Acfbodycontent_BodyContent_LargeImageBlock":
          case "WPGraphQL_Resource_Acfbodycontent_BodyContent_LargeImageBlock":
          case "WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_LargeImageBlock":
          case "WPGraphQL_LegalIntervention_Acfbodycontent_BodyContent_LargeImageBlock":
          case "WPGraphQL_PolicySubmission_Acfbodycontent_BodyContent_LargeImageBlock":
            return <LargeImageBlock key={index} image={slice.image} />

          case "WPGraphQL_Page_Acfbodycontent_BodyContent_SmallImageBlock":
          case "WPGraphQL_Article_Acfbodycontent_BodyContent_SmallImageBlock":
          case "WPGraphQL_Resource_Acfbodycontent_BodyContent_SmallImageBlock":
          case "WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_SmallImageBlock":
          case "WPGraphQL_LegalIntervention_Acfbodycontent_BodyContent_SmallImageBlock":
          case "WPGraphQL_PolicySubmission_Acfbodycontent_BodyContent_SmallImageBlock":
            return (
              <SmallImageBlock
                key={index}
                contentHeading={slice.contentHeading}
                contentHeadingId={
                  slice.contentHeading
                    ? slugify(slice.contentHeading)
                    : undefined
                }
                image={slice.image}
              />
            )
          case "WPGraphQL_Page_Acfbodycontent_BodyContent_VideoBlock":
          case "WPGraphQL_Article_Acfbodycontent_BodyContent_VideoBlock":
          case "WPGraphQL_Resource_Acfbodycontent_BodyContent_VideoBlock":
          case "WPGraphQL_NewsAndEvent_Acfbodycontent_BodyContent_VideoBlock":
          case "WPGraphQL_LegalIntervention_Acfbodycontent_BodyContent_VideoBlock":
          case "WPGraphQL_PolicySubmission_Acfbodycontent_BodyContent_VideoBlock":
            return (
              <VideoPlayer
                key={index}
                contentHeading={slice.contentHeading}
                contentHeadingId={
                  slice.contentHeading
                    ? slugify(slice.contentHeading)
                    : undefined
                }
                videoUrl={slice.videoUrl}
              />
            )
          case "WPGraphQL_Page_Acfbodycontent_BodyContent_EditableTiles":
          case "WPGraphQL_Resource_Acfbodycontent_BodyContent_EditableTiles":
          case "WPGraphQL_Article_Acfbodycontent_BodyContent_EditableTiles":
            return (
              <DetailCardsEditableTiles
                key={index}
                sectionHeading={slice.sectionHeading}
                sectionHeadingId={
                  slice.sectionHeading
                    ? slugify(slice.sectionHeading)
                    : undefined
                }
                cards={slice.cards}
              />
            )
          default:
            return false
        }
      })}
    </div>
  )
}

BodyContentRenderer.propTypes = {}

export default BodyContentRenderer
