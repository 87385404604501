/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import React from "react"

import relativeUrl from "src/helpers/relativeUrl"
import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"

const TextContent = (props) => {
  // We need all tables to be wrapped in a div so they don't break at small screen sizes
  let content = props.content

  let scripts = [];

  if(content && content.includes("<script") && (typeof document !== 'undefined')){
    while(content.includes("<script")){
        const openTag = "<script" // we leave out the closing `>` as there will be attributes
        const closeTag = "</script>"
        const openTagIndex = content.indexOf(openTag)
        const closeTagIndex = content.indexOf(closeTag)

        const scriptContent = content.substring(openTagIndex, closeTagIndex + closeTag.length);
        content = content.replace(scriptContent, "");
        scriptContent = scriptContent.replace("<p>", "").replace("</p>", "");

        let scriptNode = document.createElement("div");
        scriptNode.innerHTML = scriptContent;
        scripts.push({src: scriptNode.childNodes[0]?.src, text: scriptNode.childNodes[0]?.innerText});
    }
  }

  if (content && content.includes("<table")) {
    const openTag = "<table" // we leave out the closing `>` as there could be attributes
    const closeTag = "</table>"
    const openTagIndex = content.indexOf(openTag)
    const closeTagIndex = content.indexOf(closeTag)
    const contentArr = content.split("")
    contentArr.splice(openTagIndex, 0, `<div class="table-wrapper">`)
    contentArr.splice(closeTagIndex + closeTag.length + 1, 0, `</div>`)
    content = contentArr.join("")
  }

  return (
    <>
    <Helmet>
        {scripts.map((script) => {
            if(script.src){
                return <script src={script.src} />
            }else{
                return <script type="text/javascript">{script.text}</script>
            }
        })}
    </Helmet>
    <Section sx={{ variant: "section.medium" }}>
      <Container>
        <Columns>
          <Column
            sx={{
              "[dir=rtl] &": {
                paddingLeft: "10px",
              },
              variant: "styles",
              width: [null, null, null, "33.33333333%"],
              mb: -4,
            }}
          >
            {props.contentHeading && (
              <Themed.h2 id={props.contentHeadingId}>
                {props.contentHeading}
              </Themed.h2>
            )}
          </Column>
          <Column
            sx={{
              width: [null, null, null, "58.33333333%"],
              ml: [null, null, null, "8.33333333%"],
              variant: "styles",
            }}
          >
            <div
              sx={{
                variant: "styles",
              }}
              dangerouslySetInnerHTML={{ __html: relativeUrl(content) }}
            />
          </Column>
        </Columns>
      </Container>
    </Section>
    </>
  )
}

TextContent.propTypes = {
  contentHeading: PropTypes.string,
  content: PropTypes.string.isRequired,
}

export default TextContent
