/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

import PropTypes from "prop-types"
import Link from "src/components/Link"
import isHTML from "src/helpers/isHTML"
import formatURL from "src/helpers/formatURL"

const DetailCard = (props) => {
  return (
    props.link && (
      <Link
        to={formatURL(props.link.url)}
        sx={{
          variant: "cards.whiteToGreen",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 8,
          textDecoration: "none",
        }}
        aria-label={props.title}
      >
        <article
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            minHeight: [124, 124, 190],
            p: [4, null, 5, 6],
          }}
        >
          <div>
            {props.title && (
              <h3
                sx={{
                  variant: "links.h3",
                }}
              >
                {props.title}
              </h3>
            )}
            {props.introduction && isHTML(props.introduction) ? (
              <Themed.div
                dangerouslySetInnerHTML={{ __html: props.introduction }}
              />
            ) : (
              <Themed.p>{props.introduction}</Themed.p>
            )}
          </div>

          {props.footer && (
            <footer>{props.footer && <p>{props.footer}</p>}</footer>
          )}
        </article>
      </Link>
    )
  )
}

DetailCard.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  introduction: PropTypes.string,
  footer: PropTypes.string,
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

DetailCard.defaultProps = {
  date: null,
  introduction: null,
  footer: null,
  link: null,
}

export default DetailCard
