/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Accordion from "./Accordion"
import Timeline from "./Timeline"

const AccessibleAccordion = ({ display, ...rest }) => {
  if (display === "timeline") {
    return <Timeline {...rest} />
  }

  return <Accordion {...rest} />
}

AccessibleAccordion.propTypes = {
  display: PropTypes.oneOf(["accordion", "timeline"]),
}

export default AccessibleAccordion
