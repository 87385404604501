/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import relativeUrl from "src/helpers/relativeUrl"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

const AccessibleAccordionAccordion = ({ accordion, ...rest }) => {
  return (
    <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} {...rest}>
      {accordion.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            data-testid={`accordion-item`}
            sx={{
              borderTop: (theme) => `2px solid ${theme.colors.yellow}`,
              "&:last-child": {
                borderBottom: (theme) => `2px solid ${theme.colors.yellow}`,
              },
            }}
          >
            <AccordionItemHeading data-testid={`accordion-item-heading`}>
              <AccordionItemButton
                data-testid={`accordion-item-button`}
                sx={{
                  variant: "accordion.button",
                  pl: [2, 2, 3],
                  pr: "2.5rem",
                  py: [1, 1, 2],
                  my: 2,
                }}
              >
                <span
                  sx={{
                    variant: "styles.h4",
                    mb: false,
                  }}
                >
                  {item.heading}
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              data-testid={`accordion-item-panel`}
              sx={{
                variant: "styles",
                pt: 2,
                pb: 4,
                px: [2, 2, 3],
              }}
              dangerouslySetInnerHTML={{
                __html: relativeUrl(item.content),
              }}
            ></AccordionItemPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

AccessibleAccordionAccordion.propTypes = {
  accordion: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default AccessibleAccordionAccordion
