/** @jsx jsx */
import { jsx, Themed } from "theme-ui"

import SectionVertical from "src/components/SectionVertical"
import Section from "src/components/Section"
import SectionNarrow from "src/components/SectionNarrow"
import Container from "src/components/Container"
import ColumnsNarrow from "src/components/ColumnsNarrow"
import ColumnCardsNarrowThirds from "src/components/ColumnCardsNarrowThirds"
import DetailCardLarge from "src/components/DetailCardLarge"

const DetailCardsEditableTiles = (props) => {
  const { cards } = props
  return (
    cards &&
    cards.length > 0 && (
      <SectionVertical as="section" sx={{ variant: "section.large" }}>
        <Section>
          <Container>
            {props.sectionHeading && (
              <Themed.h2 id={props.sectionHeadingId}>
                {props.sectionHeading}
              </Themed.h2>
            )}
          </Container>
        </Section>
        <SectionNarrow>
          <Container>
            <ColumnsNarrow>
              {cards.map((card, index) => {
                return (
                  <ColumnCardsNarrowThirds key={index}>
                    <DetailCardLarge
                      title={card.cardTitle}
                      introduction={card.cardContent}
                      footer={card.cardFooter}
                      link={card.link}
                    />
                  </ColumnCardsNarrowThirds>
                )
              })}
            </ColumnsNarrow>
          </Container>
        </SectionNarrow>
      </SectionVertical>
    )
  )
}

export default DetailCardsEditableTiles
