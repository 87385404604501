/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import relativeUrl from "src/helpers/relativeUrl"
import CircleTick from "src/assets/svg/circle-tick.inline.svg"
import CircleDots from "src/assets/svg/circle-dots.inline.svg"
import CircleArrowDown from "src/assets/svg/circle-arrow-down.inline.svg"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

const AccessibleAccordionTimeline = ({ accordion, ...rest }) => {
  return (
    <div sx={{ position: "relative" }}>
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        preExpanded={["accordion-item-0"]}
        sx={{
          "::after": {
            content: '""',
            position: "absolute",
            top: "24px",
            left: "15p",
            display: "block",
            width: 2,
            height: ["calc(100% - 80px)", "calc(100% - 48px)"],
            backgroundColor: (theme) => theme.colors.yellow,
          },
        }}
        {...rest}
      >
        {accordion.map((item, index) => {
          const { status = "completed" } = item
          const StatusIcon = {
            completed: CircleTick,
            in_progress: CircleDots,
            next_stages: CircleArrowDown,
          }[status]
          return (
            <AccordionItem
              key={index}
              data-testid={`accordion-item`}
              uuid={`accordion-item-${index}`}
              sx={{ paddingLeft: "33px" }}
            >
              <div
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <StatusIcon
                  sx={{
                    position: "absolute",
                    left: "-47px",
                    zIndex: 2,
                  }}
                />
                <AccordionItemHeading
                  data-testid={`accordion-item-heading`}
                  sx={{ flex: "1" }}
                >
                  <AccordionItemButton
                    data-testid={`accordion-item-button`}
                    sx={{
                      variant: "accordion.button",
                      pl: [2, 2, 3],
                      pr: "2.5rem",
                      py: [1, 1, 2],
                      my: 2,
                    }}
                  >
                    <span
                      sx={{
                        variant: "styles.h4",
                        mb: false,
                      }}
                    >
                      {item.heading}
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </div>
              <AccordionItemPanel
                data-testid={`accordion-item-panel`}
                sx={{
                  variant: "styles",
                  pt: 2,
                  pb: 4,
                  px: [2, 2, 3],
                }}
                dangerouslySetInnerHTML={{
                  __html: relativeUrl(item.content),
                }}
              ></AccordionItemPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
  )
}

AccessibleAccordionTimeline.propTypes = {
  accordion: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default AccessibleAccordionTimeline
