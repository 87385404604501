/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import AccessibleAccordion from "src/components/AccessibleAccordion"

const Accordion = (props) => {
  return (
    <Section sx={{ variant: "section.medium" }}>
      <Container>
        <Columns>
          <Column
            sx={{
              variant: "styles",
              width: [null, null, null, "33.33333333%"],
              mb: -4,
            }}
          >
            {props.contentHeading && (
              <Themed.h2 id={props.contentHeadingId}>
                {props.contentHeading}
              </Themed.h2>
            )}
          </Column>
          <Column
            sx={{
              width: [null, null, null, "58.33333333%"],
              ml: [null, null, null, "8.33333333%"],
            }}
          >
            <div
              sx={{
                variant: "styles",
              }}
            >
              <AccessibleAccordion
                accordion={props.accordion}
                display={props.display}
              />
            </div>
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

Accordion.propTypes = {
  contentHeading: PropTypes.string,
  display: PropTypes.oneOf(["accordion", "timeline"]),
  accordion: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default Accordion
